import dynamic from "next/dynamic";

const NameSectionA = dynamic(() =>
  import("./NameSectionA/NameSectionA.controller")
);
const AssessmentFeedback = dynamic(() =>
  import("./AssessmentFeedback/AssessmentFeedback.view")
);
const GuestLoginSection = dynamic(() =>
  import("./GuestLoginSection/GuestLoginSection.controller")
);
const CourseLeadsB = dynamic(() =>
  import("./CourseLeadsB/CourseLeadsB.controller")
);
const EmbedController = dynamic(() => import("./Embed/Embed.controller"));
const PricingA = dynamic(() => import("./PricingA/PricingA.controller"));
const CoursesListing = dynamic(() =>
  import("./CoursesListing/CoursesListing.controller")
);
const ImgsGridController = dynamic(() =>
  import("./ImgsGrid/Imgs_Grid.controller")
);
const TextCardBGrid = dynamic(() =>
  import(
    "@/mvcComponents/CommonUI/Sections/TextCardBGrid/TextCardBGrid.controller"
  )
);
const CardPopupA = dynamic(() => import("./CardPopupA/CardPopupA.controller"));
const CardPopupB = dynamic(() => import("./CardPopupB/CardPopupB.controller"));
const TextCardA_Grid = dynamic(() =>
  import("./TextCardA_Grid/TextCardA_Grid.controller")
);
const ScholarshipBannerSection = dynamic(() =>
  import(
    "@/mvcComponents/PageComponents/ScholarshipPage/ScholarshipBannerSection/ScholarshipBannerSection.controller"
  )
);
const HaloTxtImgB = dynamic(() =>
  import("./HaloTxtImgB/HaloTxtImgB.controller")
);
const LatestJobs = dynamic(() => import("./LatestJobs/LatestJobs.controller"));
const IconTxtListB_Grid = dynamic(() =>
  import("./IconTxtListB_Grid/IconTxtListB_Grid.controller")
);
const CurriculumSection = dynamic(() =>
  import("./CurriculumSection/CurriculumSection.controller")
);
const JobsSectionController = dynamic(() =>
  import("./JobsSection/JobsSection.controller")
);
const InlineContentScrollableSection = dynamic(() =>
  import(
    "./InlineContentScrollableSection/InlineContentScrollableSection.controller"
  )
);
const FeaturesList = dynamic(() =>
  import("./FeaturesList/FeaturesList.controller")
);
const FaqsList = dynamic(() => import("./FaqsList/FaqsList.controller"));
const RichText = dynamic(() => import("./RichText/RichText.controller"));
const PillAList = dynamic(() => import("./PillAList/PillAList.controller"));
const TextLinks = dynamic(() => import("./TextLinks/TextLinks.controller"));
const DropGridA = dynamic(() => import("./DropGridA/DropGridA.controller"));
const DropList_A = dynamic(() => import("./DropList_A/DropList_A.controller"));
const HaloTxtImgA = dynamic(() =>
  import("./HaloTxtImgA/HaloTxtImgA.controller")
);
const ImgTxtC_Grid = dynamic(() =>
  import("./ImgTxtC_Grid/ImgTxtC_Grid.controller")
);
const GamesByAlumni = dynamic(() =>
  import("./GamesByAlumni/GamesByAlumni.controller")
);
const CourseHaloController = dynamic(() =>
  import("./CourseHalo/CourseHalo.controller")
);
const ImgCardC_Grid = dynamic(() =>
  import("./ImgCardC_Grid/ImgCardC_Grid.controller")
);
const ProcessA_Grid = dynamic(() =>
  import("./ProcessA_Grid/ProcessA_Grid.controller")
);
const ImageAutoScroll = dynamic(() =>
  import("./ImageAutoScroll/ImageAutoScroll.controller")
);
const ImgCardA_Scroll = dynamic(() =>
  import("./ImgCardA_Scroll/ImgCardA_Scroll.controller")
);
const IconTextCardGrid = dynamic(() =>
  import("./IconTextCardGrid/IconTextCardGrid.controller")
);
const CourseLeads_Halo = dynamic(() =>
  import("./CourseLeads_Halo/CourseLeads_Halo.controller")
);
const ImageTextA_Scroll = dynamic(() =>
  import("./ImageTextA_Scroll/ImageTextA_Scroll.controller")
);
const StatisticsSection = dynamic(() =>
  import("./StatisticsSection/StatisticsSection.controller")
);
const PaidCouresListController = dynamic(() =>
  import("./PaidCoursesList/PaidCouresList.controller")
);
const CourseFeeStructure = dynamic(() =>
  import("./CourseFeeStructure/CourseFeeStructure.controller")
);
const PersonACard_Scrollable = dynamic(() =>
  import("./PersonACard_Scrollable/PersonACard_Scrollable.controller")
);
const PersonBCard_Scrollable = dynamic(() =>
  import("./PersonBCard_Scrollable/PersonBCard_Scrollable.controller")
);
const PersonCCard_AutoScroll = dynamic(() =>
  import("./PersonCCard_AutoScroll/PersonCCard_AutoScroll.controller")
);
const AssessmentsListController = dynamic(() =>
  import(
    "@/mvcComponents/CommonUI/Sections/AssessmentsList/AssessmentsList.controller"
  )
);
const OutlinedIconTextCardGrid_Image = dynamic(() =>
  import(
    "./OutlinedIconTextCardGrid_Image/OutlinedIconTextCardGrid_Image.controller"
  )
);
const ScholarshipsList = dynamic(() =>
  import("./ScholarshipsList/ScholarshipsList.controller")
);
const TxtLstController = dynamic(() => import("./TxtLst/TxtLst.controller"));
const OnlyMediaController = dynamic(() =>
  import("./OnlyMedia/OnlyMedia.controller")
);
const CourseHaloV2Controller = dynamic(() =>
  import("./CourseHaloV2/CourseHaloV2.controller")
);
const ImgCardA_GridController = dynamic(() =>
  import("./ImgCardA_Grid/ImgCardA_Grid.controller")
);

const Compiler = dynamic(() => import("./Compiler/compiler.controller"));

const TabLinks = dynamic(() => import("./TabLinks/TabLinks.controller"));

const CoursesListSticky = dynamic(() =>
  import(
    "@/mvcComponents/CommonUI/Sections/CoursesListSticky/CoursesListSticky.controller"
  )
);

const CmsFldTxtView = dynamic(() =>
  import("@/mvcComponents/v2/FldTxt/FldTxt.view").then(
    (mod) => mod.CmsFldTxtView
  )
);
const CmsIcnLstView = dynamic(() =>
  import("@/mvcComponents/v2/IcnLst/IcnLst.view").then(
    (mod) => mod.CmsIcnLstView
  )
);
const CmsSmIcnLstView = dynamic(() =>
  import("@/mvcComponents/v2/SmIcnLst/SmIcnLst.view").then(
    (mod) => mod.CMSSmIcnLstView
  )
);
const CmsShareCrdView = dynamic(() =>
  import("@/mvcComponents/v2/ShareCrd/ShareCrd.view").then(
    (mod) => mod.CmsShareCrdView
  )
);
const CMSTextualSectionView = dynamic(() =>
  import("@/mvcComponents/v2/TextualSection/TextualSection.view").then(
    (mod) => mod.CMSTextualSectionView
  )
);

const CMSDropDownView = dynamic(() =>
  import("@/mvcComponents/v2/DropDown/DropDown.view").then(
    (mod) => mod.CMSDropDownView
  )
);
const CMSDtlsCrdView = dynamic(() =>
  import("@/mvcComponents/v2/DtlsCrd/DtlsCrd.view").then(
    (mod) => mod.CMSDtlsCrdView
  )
);
const CrsDetailView = dynamic(() =>
  import("@/mvcComponents/v2/CrsDetail/CrsDetail.view")
);
const CMSDynmCrdsView = dynamic(() =>
  import("@/mvcComponents/v2/DynmCrds/DynmCrds.view").then(
    (mod) => mod.CMSDynmCrdsView
  )
);
const CMSHaloSctnView = dynamic(() =>
  import("@/mvcComponents/v2/HaloSctn/HaloSctn.view").then(
    (mod) => mod.CMSHaloSctnView
  )
);
const CMSScrlDynmCrdsView = dynamic(() =>
  import("@/mvcComponents/v2/ScrlDynmCrds/ScrlDynmCrds.view").then(
    (mod) => mod.CMSScrlDynmCrdsView
  )
);
const HTMLEmbedView = dynamic(() =>
  import("@/mvcComponents/v2/HTMLEmbed/HTMLEmbed.view")
);
const CMSWaitlistCrdController = dynamic(() =>
  import("@/mvcComponents/v2/WaitlistCrd/WaitlistCrd.controller").then(
    (mod) => mod.CMSWaitlistCrdController
  )
);
const FormSectionController = dynamic(() =>
  import("./FormSection/FormSection.controller")
);

const SectionsMap = {
  "grid-a": IconTextCardGrid,
  "lead-form": CourseLeads_Halo,
  "course-a": PaidCouresListController,
  "txt-links": TextLinks,
  "pill-a-list": PillAList,
  faq: FaqsList,
  "ita-scroll": ImageTextA_Scroll,
  "dropper-list-a": DropList_A,
  "drop-grid-a": DropGridA,
  assessment: AssessmentsListController,
  "txt-content": RichText,
  "pa-scroll": PersonACard_Scrollable,
  "pb-scroll": PersonBCard_Scrollable,
  "pc-auto": PersonCCard_AutoScroll,
  "course-halo": CourseHaloController,
  "img-auto": ImageAutoScroll,
  "course-fee": CourseFeeStructure,
  "game-desc": GamesByAlumni,
  "list-image-a": OutlinedIconTextCardGrid_Image,
  statistics: StatisticsSection,
  "img-card-c": ImgCardC_Grid,
  "img-card-a": ImgCardA_GridController,
  "img-text-c": ImgTxtC_Grid,
  "halo-txt-img-a": HaloTxtImgA,
  "halo-txt-img-b": HaloTxtImgB,
  "process-a": ProcessA_Grid,
  "txt-card-a": TextCardA_Grid,
  "txt-card-b": TextCardBGrid,
  "latest-jobs": LatestJobs,
  "icon-txt-list-b": IconTxtListB_Grid,
  syllabus: CurriculumSection,
  jobs: JobsSectionController,
  "inline-a": InlineContentScrollableSection,
  "ftr-list": FeaturesList,
  "card-popup-a": CardPopupA,
  "card-popup-b": CardPopupB,
  "ctr-grid-a": ScholarshipsList,
  "schlp-dts": ScholarshipBannerSection,
  txtlst: TxtLstController,
  "only-media": OnlyMediaController,
  crshalov2: CourseHaloV2Controller,
  compiler: Compiler,
  "tab-links": TabLinks,
  "img-grid": ImgsGridController,
  "courses-listing": CoursesListing,
  "pricing-a": PricingA,
  "icn-lst": CmsIcnLstView,
  "fld-txt": CmsFldTxtView,
  "abt-course": CMSTextualSectionView,
  "sm-icn-lst": CmsSmIcnLstView,
  "share-crd": CmsShareCrdView,
  embed: EmbedController,
  "dtls-crd": CMSDtlsCrdView,
  "crs-detail": CrsDetailView,
  "dynm-crds": CMSDynmCrdsView,
  "drop-down": CMSDropDownView,
  "halo-sctn": CMSHaloSctnView,
  "scrl-dynm-crds": CMSScrlDynmCrdsView,
  "lead-form-b": CourseLeadsB,
  "waitlist-crd": CMSWaitlistCrdController,
  html: HTMLEmbedView,
  "guest-form": GuestLoginSection,
  "name-a": NameSectionA,
  "form-section": FormSectionController,
  "crs-list-sticky": CoursesListSticky,
  "assessment-feedback": AssessmentFeedback
};
export default SectionsMap;
