import dynamic from "next/dynamic";
const DropDownView = dynamic(() => import("@/mvcComponents/v2/DropDown/DropDown.view"));
const DtlsCrdView = dynamic(() => import("@/mvcComponents/v2/DtlsCrd/DtlsCrd.view"));
const DynmCrdsView = dynamic(() => import("@/mvcComponents/v2/DynmCrds/DynmCrds.view"));
const TextualSectionView = dynamic(() => import("@/mvcComponents/v2/TextualSection/TextualSection.view"));
const ScrlDynmCrdsView = dynamic(() =>
    import("@/mvcComponents/v2/ScrlDynmCrds/ScrlDynmCrds.view")
);
const HTMLEmbedView = dynamic(() => import("@/mvcComponents/v2/HTMLEmbed/HTMLEmbed.view"));
const SmIcnLstView = dynamic(() => import("@/mvcComponents/v2/SmIcnLst/SmIcnLst.view"));
const WaitlistCrdController = dynamic(() => import("@/mvcComponents/v2/WaitlistCrd/WaitlistCrd.controller"));

const SectionsMap = {
    "textual": TextualSectionView,
    "drop-down": DropDownView,
    "dynm-crds": DynmCrdsView,
    "dtls-crd": DtlsCrdView,
    "scrl-dynm-crds": ScrlDynmCrdsView,
    html: HTMLEmbedView,
    "sm-icn-lst": SmIcnLstView,
    "waitlist-crd": WaitlistCrdController
}

export default SectionsMap
