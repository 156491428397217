import React from "react";
import SectionsMap from "./PageBuilder.model";
import dynamic from "next/dynamic";
import SectionBuilderController from "./SectionBuilder.controller";
const Footer = dynamic(() => import("../Footer/Footer.controller"));
import styles from "./PageBuilder.module.css";

const PageBuilder = ({
  sections,
  rightStickySections,
  showFooter = true,
  pageWidth_px,
  pageGap_px,
  mainLayoutWidth,
  rightStickyLayoutWidth,
  spaceBetweenSections_px,
}) => {
  if (sections?.length < 1) {
    return <>{showFooter !== false ? <Footer /> : null}</>;
  }

  pageWidth_px = pageWidth_px || 1220;
  pageGap_px = pageGap_px || 20;
  mainLayoutWidth = mainLayoutWidth || 70;
  rightStickyLayoutWidth = rightStickyLayoutWidth || 30;
  spaceBetweenSections_px = spaceBetweenSections_px || 20;

  return (
    <>
      <div
        className={styles["sections-wrapper"]}
        style={{
          "--page-width": `${pageWidth_px}px`,
          "--page-gap": `${pageGap_px}px`,
          "--main-layout-width": `${mainLayoutWidth}%`,
          "--right-sticky-layout-width": `${rightStickyLayoutWidth}%`,
          "--section-gap": `${spaceBetweenSections_px}px`,
        }}
      >
        <div
          className={styles["sections"]}
          style={{
            flexBasis:
              rightStickySections.length > 0
                ? "var(--main-layout-width)"
                : "100%",
            boxSizing: "border-box",
          }}
        >
          <SectionBuilderController
            sections={sections}
            PageBuilderMap={SectionsMap}
          />
        </div>

        {rightStickySections.length > 0 && (
          <div
            className={styles["right-sticky-sections"]}
            style={{
              flexBasis: "var(--right-sticky-layout-width)",
              display: "block",
              boxSizing: "border-box",
            }}
          >
            <SectionBuilderController
              sections={rightStickySections}
              PageBuilderMap={SectionsMap}
            />
          </div>
        )}
      </div>

      {showFooter !== false ? <Footer /> : null}
    </>
  );
};

export const getPageBuilderProps = ({
  sections = [],
  spaceBetweenSections_px,
  rightStickySections = [],
  pageWidth_px,
  pageGap_px,
  mainLayoutWidth,
  rightStickyLayoutWidth,
  showFooter = true,
} = {}) => {
  return {
    showFooter,
    sections,
    rightStickySections,
    spaceBetweenSections_px,
    pageWidth_px,
    pageGap_px,
    mainLayoutWidth,
    rightStickyLayoutWidth,
  };
};

export default PageBuilder;
